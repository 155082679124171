import '../styles/components/button.scss'

import type { ReactNode } from 'react'
import React from 'react'

import { classNameMap, joinClassNames } from '../utils/tsx'

export interface ButtonProps {
  href?: string
  variant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary'
  icon?: ReactNode
  onClick?: () => void
  className?: string
  disabled?: boolean
}

export const Button: React.FC<ButtonProps> = ({
  href,
  children,
  variant = 'primary',
  icon,
  onClick,
  className,
  disabled,
}) =>
  React.createElement(
    href ? 'a' : 'button',
    {
      className: joinClassNames(
        'button',
        `button--variant-${variant}`,
        classNameMap({ 'button--disabled': disabled }),
        className
      ),
      onClick,
      disabled,
      tabIndex: disabled ? -1 : undefined,
    },
    <>
      <span className="button__content">{children}</span>
      {icon ? <span className="button__icon">{icon}</span> : null}
    </>
  )
